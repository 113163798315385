import React from 'react';
import styled from 'styled-components';
import Colours from '../utils/colours';
import MediaQueries from '../utils/media-queries';
import { Link } from 'gatsby';

const SectionContainer = styled.section`
  background: ${Colours.white};
  margin: 6rem auto;
  width: 100%;
  @media only screen and (max-width: ${MediaQueries.insect}) {
    margin: 6rem auto;
  }
`;
const SectionContents = styled.section`
  background: ${Colours.white};
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 6vw;
  display: flex;
  @media only screen and (max-width: ${MediaQueries.insect}) {
    flex-direction: column;
    padding: 0 0 2rem;
  }
`;
const ImageContainer = styled.div`
  flex-shrink: 0;
  img {
    width: 350px;
    @media only screen and (max-width: ${MediaQueries.fish}) {
      width: 200px;
      margin-left: 1rem;
    }
     @media only screen and (max-width: ${MediaQueries.insect}) {
      display: none;
      /*display: block;
      margin: 1rem auto;
    } */
  }
`;
const HelloContainer = styled.div`
  margin: 0 0 0 4rem;
  align-self: center;
  h1 {
    font-size: calc(2.5rem + 2vw);
    font-weight: 900;
    z-index: 1;
    position: relative;
    :before {
      content: '';
      display: inline-block;
      position: absolute;
      z-index: -1;
      right: 0;
      top: -2rem;
      width: 10rem;
      height: 10rem;
      border-radius: 50%;
      background-color: ${Colours.primary};

      @media only screen and (max-width: ${MediaQueries.insect}) {
        width: 6rem;
        height: 6rem;
        right: 15%;
        top: 1rem;
      }
    }
  }
  p {
    z-index: 1;
    position: relative;
    :before {
      content: '';
      display: inline-block;
      position: absolute;
      z-index: -1;
      right: 60%;
      bottom: -2rem;
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      background-color: ${Colours.primary};
    }
  }
  a {
    position: relative;
    z-index: 1;
    color: inherit;
    font-weight: bolder;
    font-family: Catamaran, sans-serif;
    background: ${Colours.secondary2};
    padding: 0.25rem 1.5rem;
    border-radius: 0.5rem;
    text-decoration: none;
  }
  @media only screen and (max-width: ${MediaQueries.insect}) {
    margin: 0 1rem;
  }
`;

export default () => {
  return (
    <SectionContainer>
      <SectionContents>
        <ImageContainer>
          <img title='' src='/assets/LargeTitleIllustration01.png' alt='' />
        </ImageContainer>
        <HelloContainer>
          <h1>Hey, I'm Josh</h1>
          <p>
            I'm a designer and real world content developer. From logo design to product design. Web
            development to packaging - if there's design involved, count me in!
          </p>
          <Link to='#services'>Get some design!</Link>
        </HelloContainer>
      </SectionContents>
    </SectionContainer>
  );
};
