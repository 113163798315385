import React from 'react';
import Layout from '../components/Layout';
import TitleHeroSection from '../components/TitleHeroSection';
import ServicesSection from '../components/ServicesSection';
import NeedADesignerSection from '../components/NeedADesignerSection.js';
import ArticleSection from '../components/ArticleSection.js';
import Colours from '../utils/colours';
import styled from 'styled-components';
import MediaQueries from '../utils/media-queries';
import SEO from '../components/SEO/SEO';

const IndexSections = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  padding: 0;
  @media only screen and (max-width: ${MediaQueries.fish}) {
    padding: 0 0.2rem;
      h3 {
        margin-left: 1rem;
      }
    }
  }
  @media only screen and (max-width: ${MediaQueries.insect}) {
    margin-top: 1rem;
    grid-template-columns: 1fr;
    grid-gap: 0.2rem;
    padding: 0;
  }
`;

const IndexPage = () => {
  return (
    <Layout id='top'>
      <SEO homepage />
      <a href='/'>
        <img
          src='assets/pencilarrow.png'
          alt='Back to top'
          style={{
            position: 'fixed',
            right: '30px',
            bottom: '-38px',
            height: '120px',
            zIndex: '3',
          }}
        />
      </a>
      <IndexSections>
        <TitleHeroSection></TitleHeroSection>
        <ServicesSection></ServicesSection>
        <NeedADesignerSection></NeedADesignerSection>
        <ArticleSection></ArticleSection>
      </IndexSections>
    </Layout>
  );
};

export default IndexPage;
