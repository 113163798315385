import React from 'react';
import styled from 'styled-components';
import Colours from '../utils/colours';
import MediaQueries from '../utils/media-queries';
import { Link } from 'gatsby';

const SectionContainer = styled.section`
  background: ${Colours.offWhite};
  margin: 0 auto;
  width: 100%;
`;
const SectionContents = styled.section`
  background: ${Colours.offWhite};
  margin: 0 auto;
  max-width: 1200px;
  padding: 0rem 1rem 3rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas:
    'title title title'
    'imageG imageP imageW'
    'titleG titleP titleW'
    'infoG infoP infoW'
    'clickG clickP clickW';
  grid-column-gap: 2rem;
  @media only screen and (max-width: ${MediaQueries.insect}) {
    grid-template-columns: 1fr;
    grid-template-areas:
      'title'
      'imageG'
      'titleG'
      'infoG'
      'clickG'
      'imageP'
      'titleP'
      'infoP'
      'clickP'
      'imageW'
      'titleW'
      'infoW'
      'clickW';
    padding: 1rem 1rem 4rem;
  }
  div {
    @media only screen and (max-width: ${MediaQueries.insect}) {
      width: 300px;
      align-self: center;
      justify-self: center;
      padding-top: 1rem;
    }
  }
  img {
    position: absolute;
    width: 250px;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    @media only screen and (max-width: ${MediaQueries.fish}) {
      width: 250px;
    }
    @media only screen and (max-width: ${MediaQueries.insect}) {
      width: 100%;
      position: relative;
      margin: 0 auto;
    }
  }
  h2:not(.servicetitle) {
    text-align: center;
    position: relative;
    z-index: 1;
    :nth-of-type(2) {
      :before {
        content: '';
        display: inline-block;
        position: absolute;
        height: 6rem;
        width: 6rem;
        top: -3rem;
        left: 0.5rem;
        border-radius: 50%;
        background: ${Colours.primary};
        z-index: -1;
        @media only screen and (max-width: ${MediaQueries.insect}) {
          left: 25%;
          top: -2.4rem;
        }
      }
    }
    :nth-of-type(4) {
      :before {
        content: '';
        display: inline-block;
        position: absolute;
        height: 5rem;
        width: 5rem;
        bottom: -3rem;
        right: 4rem;
        border-radius: 50%;
        background: ${Colours.primary};
        z-index: -1;
        @media only screen and (max-width: ${MediaQueries.insect}) {
          right: 25%;
          bottom: -3.4rem;
        }
      }
    }
  }
  div {
    margin: 3rem 0 2rem;
    height: 200px;
    position: relative;
    @media only screen and (max-width: ${MediaQueries.fish}) {
      margin: 3rem 0 1rem;
    }
    @media only screen and (max-width: ${MediaQueries.insect}) {
      height: 100%;
    }
  }
  a:not(.imageLink) {
    justify-self: flex-end;
    color: inherit;
    margin: 1rem 0.2rem 0;
    padding: 0.5rem 0 0;
    text-decoration: none;
    border-bottom: 3px solid ${Colours.secondary2};
    transition: padding 0.3s cubic-bezier(0, 1.5, 0.3, 1.5);
    :hover {
      padding: 0.5rem 2rem 0 0;
    }
  }
  @media only screen and (max-width: ${MediaQueries.fish}) {
    //background: red;
    margin: 0 auto;
  }
  @media only screen and (max-width: ${MediaQueries.insect}) {
    //background: green;
    margin: 0 auto;
  }
  @media only screen and (max-width: ${MediaQueries.nano}) {
    //background: blue;
    margin: 0 auto;
  }
`;

export default () => {
  return (
    <SectionContainer id='services'>
      <SectionContents>
        <h2
          className='servicetitle'
          style={{
            gridArea: 'title',
            margin: '1.6rem 1rem 1rem',
            fontSize: 'calc(1.4rem + 2vw)',
            /* placeSelf: 'center', */
            textDecoration: 'underline',
            textDecorationColor: Colours.secondary2,
          }}
        >
          Things I do...
        </h2>
        <div style={{ gridArea: 'imageG' }}>
          <Link className='imageLink' to='/contact?graphic'>
            <img
              src='/assets/LargeGraphicDesignIllustration01.png'
              alt='Graphic Design Illustration'
              //title='Graphic Design Illustration'
            />
          </Link>
        </div>
        <h2 style={{ gridArea: 'titleG' }}>Graphic Design</h2>
        <p style={{ gridArea: 'infoG' }}>
          Whether it's a logo or an advert, I strive to create designs that mimic your philosophies
          and truly resonate with the real people and customers you need to connect with.
        </p>
        <Link to='/contact?graphic' style={{ gridArea: 'clickG' }}>
          Get some graphics!
        </Link>
        <div style={{ gridArea: 'imageP' }}>
          <Link className='imageLink' to='/contact?product'>
            <img
              src='/assets/LargeProductDesignIllustration01.png'
              alt='Product Design Illustration'
              //title='Product Design Illustration'
            />
          </Link>
        </div>
        <h2 style={{ gridArea: 'titleP' }}>Product Design</h2>
        <p style={{ gridArea: 'infoP' }}>
          From market research to conceptualisation and design for manufacture, I can design
          products that solve real world problems and are capable of going from prototype to
          production.
        </p>
        <Link to='/contact?product' style={{ gridArea: 'clickP' }}>
          Got a product idea?
        </Link>
        <div style={{ gridArea: 'imageW' }}>
          <Link className='imageLink' to='/contact?web'>
            <img
              src='/assets/LargeWebDesignIllustration01.png'
              alt='Web Design Illustration'
              //title='Web Design Illustration'
            />
          </Link>
        </div>
        <h2 style={{ gridArea: 'titleW' }}>Web Design</h2>
        <p style={{ position: 'relative', zIndex: '1', gridArea: 'infoW' }}>
          The start of your conversion funnel, it's important that your website functions flawlessly
          for everyone, this involves the right design patterns and development frameworks.
        </p>
        <Link to='/contact?web' style={{ gridArea: 'clickW' }}>
          Need a website?
        </Link>
      </SectionContents>
    </SectionContainer>
  );
};
