import React from 'react';
import styled from 'styled-components';
import Colours from '../utils/colours';
import PostItem from '../components/PostItem';
import MediaQueries from '../utils/media-queries';
import { Link } from 'gatsby';
import { graphql, useStaticQuery } from 'gatsby';

const SectionContainer = styled.section`
  background: ${Colours.white};
  margin: 0 auto 3rem;
  padding: 4rem 0 0;
  width: 100%;
`;

const SectionTitle = styled.div`
  max-width: 1200px;
  margin: 0 auto 2rem;
  h3 {
    display: inline-block;
    margin: 0 4rem 0 1rem;
    @media only screen and (max-width: ${MediaQueries.insect}) {
      display: block;
    }
  }
  a {
    display: inline-block;
    text-decoration: none;
    border-bottom: 3px solid ${Colours.secondary2};
    transition: padding 0.3s cubic-bezier(0, 1.5, 0.3, 1.5);
    color: inherit;
    @media only screen and (max-width: ${MediaQueries.insect}) {
      margin: 0 0 0 1rem;
    }
    :hover {
      padding: 0rem 0 0 2rem;
    }
    :visited {
      color: inherit;
    }
  }
`;

const SectionContents = styled.section`
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  .item {
    :nth-of-type(1) {
      :before {
        content: '';
        display: inline-block;
        position: absolute;
        z-index: -1;
        left: 20%;
        top: 6%;
        width: 4rem;
        height: 4rem;
        border-radius: 50%;
        background-color: ${Colours.primary};
      }
    }
    :nth-of-type(2) {
      :before {
        content: '';
        display: inline-block;
        position: absolute;
        z-index: -1;
        right: 35%;
        top: 60%;
        width: 7rem;
        height: 7rem;
        border-radius: 50%;
        background-color: ${Colours.primary};
      }
    }
    :nth-of-type(2) {
      :after {
        content: '';
        display: inline-block;
        position: absolute;
        z-index: -1;
        left: 15%;
        top: 40%;
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        background-color: ${Colours.primary};
      }
    }
    :nth-of-type(3) {
      :before {
        content: '';
        display: inline-block;
        position: absolute;
        z-index: -1;
        right: 8%;
        top: 90%;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
        background-color: ${Colours.primary};
      }
    }
  }
`;

const ArticleSectionPosts = () => {
  const data = useStaticQuery(graphql`
    query {
      Posts: allMarkdownRemark {
        edges {
          node {
            id
            excerpt(pruneLength: 75)
            timeToRead
            frontmatter {
              date(formatString: "DD MMMM")
              unformatteddate: date
              updated(formatString: "DD MMMM")
              title
              posttype
              description
              priority
              coverimage {
                childImageSharp {
                  fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
            }
            fields {
              slug
            }
          }
        }
      }
    }
  `);
  const SortBlogPosts = (a, b) => {
    if (a.node.frontmatter.unformatteddate < b.node.frontmatter.unformatteddate) {
      return 1;
    }
    if (a.node.frontmatter.unformatteddate > b.node.frontmatter.unformatteddate) {
      return -1;
    }
    return 0;
  };

  const SortedBlogs = data.Posts.edges.sort(SortBlogPosts);

  const BlogPosts = SortedBlogs.slice(0, 3).map(({ node: post }) =>
    post.frontmatter.posttype === 'Blog' ? (
      <PostItem key={post.id} post={post} type={post.frontmatter.posttype} />
    ) : null
  );

  return (
    <SectionContainer id='articles'>
      <SectionTitle>
        <h3 style={{ fontWeight: '900' }}>Recent Articles</h3>
        <Link to='/blog/'>See all articles...</Link>
      </SectionTitle>
      <SectionContents>{BlogPosts}</SectionContents>
    </SectionContainer>
  );
};

export default ArticleSectionPosts;
