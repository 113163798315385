import React from 'react';
import styled from 'styled-components';
import Colours from '../utils/colours';
import MediaQueries from '../utils/media-queries';
import { Link } from 'gatsby';

const SectionContainer = styled.section`
  position: relative;
  z-index: 1;
  background: ${Colours.black};
  color: ${Colours.white};
  margin: 0 auto;
  width: 100%;
  @media only screen and (max-width: ${MediaQueries.insect}) {
    margin: -0.2rem auto 0;
  }
`;
const SectionContents = styled.section`
  background: ${Colours.black};
  margin: 0 auto;
  max-width: 1200px;
  padding: 12rem 6vw;
  display: flex;
  flex-direction: column;
  img {
    align-self: center;
    width: 300px;
    padding: 0 0 2rem;
    margin-top: 0;
  }
  a {
    color: ${Colours.black} !important;
    position: relative;
    display: block;
    align-self: center;
    width: max-content;
    z-index: 1;
    color: inherit;
    font-size: 1.5rem;
    font-weight: bolder;
    font-family: Catamaran, sans-serif;
    background: ${Colours.secondary2};
    padding: 0.25rem 1.5rem;
    border-radius: 0.5rem;
    text-decoration: none;
  }
`;
const BigText = styled.h2`
  font-size: calc(3rem + 2vw);
  font-weight: 900;
  text-align: center;
  padding: 0 0 0.5rem;
  margin-bottom: 6rem;
`;

export default () => {
  return (
    <SectionContainer>
      <SectionContents>
        <BigText>Need a Designer?</BigText>
        {/* <img src='/assets/LargePencil01.png' /> */}
        <Link to='/contact?CountMeIn'>Yes I do.</Link>
      </SectionContents>
    </SectionContainer>
  );
};
